<template>
  <validation-observer
    ref='form'
    #default='{ invalid }'
    tag='form'
    class='repeater__form'
    style='overflow: visible'
  >
    <b-modal
      v-model='show'
      content-class='package-modal rounded-8'
      dialog-class='wameed-modal-md'
      no-fade
    >
      <template slot='modal-header-close'>
        <close-icon />
      </template>
      <template slot='modal-title'>
        <div class='d-block text-center'>
          <h3 class='text-reg-18'>
            {{ modalData != null ? $t('ads.update_btn') : $t('ads.new_btn') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col md='12'>
          <text-input
            id='package-ad'
            v-model='form.name'
            :label="$t('ads.form.name')"
            :placeholder="$t('form.text.placeholder')"
            name='package-ad'
            rules='required'
          />
        </b-col>

        <b-col md='6'>

          <wameed-input-file-upload
            fileType='images'
            field-classes='w-100'
            rule='required'
            functionType='front'
            v-model='form.image.name'
            :label="$t('ads.form.image')"
            :input-placeholder="$t('form.image.placeholder')"
            :input-append-text="$t('form.image.browse')"
            @changed='uploadProfileImage'
          />
        </b-col>
        <b-col md='6'>

          <wameed-input-file-upload
            fileType='images'
            field-classes='w-100'
            rule='required'
            functionType='front'
            v-model='form.image_web.name'
            :label="$t('ads.form.image_web')"
            :input-placeholder="$t('form.image.placeholder')"
            :input-append-text="$t('form.image.browse')"
            @changed='uploadWebImage'
          />
        </b-col>


        <b-col md='6'>
          <wameed-dropdown
            v-model='form.redirect_type_obj'
            :label="$t('ads.form.category')"
            :placeholder="$t('form.text.placeholder')"
            variant='disable'
            :items='redirectTypes'
            title='name'
            class='wameed_dropdown'

            :no_options="$t('common.no_options')"
            @input='onTypeChange'
          />
        </b-col>
        <b-col md='6' v-if='showVendorDropdown'>
          <wameed-dropdown
            :searchable='true'
            v-model='form.vendor'
            :label="$t('ads.form.vendor')"
            :placeholder="$t('form.text.placeholder')"
            variant='disable'
            :items='getVendors'
            title='name'
            class='wameed_dropdown'

            :no_options="$t('common.no_options')"

            @input='onVendorChange'
          />
        </b-col>
        <b-col md='6' v-if='showRedirectTextInput'>
          <text-input
            id='redirect'
            v-model='form.redirect'
            :label="$t('ads.form.name')"
            :placeholder="$t('form.text.placeholder')"
            name='redirect'
            rules='required'
          />
        </b-col>

        <b-col md='12' v-if='showRedirectDropDown'>
          <wameed-dropdown
            :searchable='true'
            v-model='form.redirect_obj'
            :label="form.redirect_type_obj.name"
            :placeholder="$t('form.text.placeholder')"
            variant='disable'
            :items='getRedirects'
            title='name'
            class='wameed_dropdown'

            :no_options="$t('common.no_options')"
            @input='onRedirectChange'

          />
        </b-col>

      </b-row>


      <b-row>
        <b-col md='12'>
          <hr class='divider' />
        </b-col>

        <b-col md='6'>
          <Wameed-date-picker
            v-model='form.start_at'
            placeholder='YYYY-MM-DD'
            :config='{"type":"string","mask":"YYYY-MM-DD"}'
            mode='date'
            :label="$t('common.start_at')"
            @input='clearExpireDate'
          />
        </b-col>
        <b-col md='6'>
          <Wameed-date-picker
            v-model='form.end_at'
            placeholder='YYYY-MM-DD'
            :config='{"type":"string","mask":"YYYY-MM-DD"}'
            mode='date'
            :label="$t('common.end_at')"
            :minDate='minEndDate'
          />
        </b-col>
      </b-row>
      <template slot='modal-footer'>
        <wameed-btn
          classes='  text-med-14 text-white mx-0 rounded-8'
          :title="modalData!=null ?$t('btn.update'):$t('btn.add')"
          :disabled='invalid'
          type='submit'
          variant='main'
          @onClick='submitOrder()'
        />
        <wameed-btn
          classes='  text-med-14 text-font-secondary mx-0 rounded-8 '
          :title="$t('btn.cancel')"
          type='button'
          variant='gray'
          @onClick='closeModal()'
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WameedBtn, WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import { mapActions, mapGetters } from 'vuex';
import { AdRedirectTypes, AdRedirectTypesTranslations } from '@/enums/userType.enum';


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    modalData: {
      type: Object,
      default: null
    }

  },

  data() {
    return {
      form: {
        name: '',
        image: '',
        image_web: '',
        redirect_type_obj: '',
        redirect_type: 0,
        redirect_obj: '',
        redirect: '',
        vendor: '',
        vendor_id: '',
        start_at: '',
        end_at: ''
      },
      showSuccessModal: false
    };
  },
  computed: {
    ...mapGetters({
      getVendors: 'admin/ads/getVendors',
      getRedirects: 'admin/ads/getRedirects'
    }),
    minEndDate() {
      let date = this.form.start_at;
      if (!date) {
        date = Date();
      }
      return new Date(date.toString()).toString();
    },
    showVendorDropdown() {
      return AdRedirectTypes.needVendors.includes(this.form.redirect_type);
    },
    showRedirectTextInput() {
      return AdRedirectTypes.textRedirect.includes(this.form.redirect_type);
    },
    showRedirectDropDown() {
      return AdRedirectTypes.dropdownRedirect.includes(this.form.redirect_type);
    },
    redirectTypes() {
      return AdRedirectTypesTranslations;
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }
  },
  watch: {
    modalData(newVal) {
      if (newVal != null) {
        this.form = newVal;
        this.form.redirect_type_obj = AdRedirectTypesTranslations.filter(type => type.id === this.form.redirect_type)[0];
        this.form.vendor = this.getVendors.filter(vendor => vendor.id === this.form.vendor_id)[0];
        if(this.showRedirectDropDown){
          this.form.redirect_obj = this.getRedirects.filter(redirect => redirect.id === this.form.redirect)[0];
        }
      } else {
        this.form = {
          name: '',
          image: '',
          image_web: '',
          redirect_type_obj: '',
          redirect_type: 0,
          vendor: '',
          vendor_id: '',
          redirect: '',
          start_at: '',
          end_at: ''
        };
      }
    }
  },
  created() {
    if (this.modalData != null) {
      this.form = this.modalData;
    }
  },
  methods: {
    ...mapActions({
      create: 'admin/ads/create',
      update: 'admin/ads/update'
    }),

    clearExpireDate() {
      if (this.form.start_at > this.form.end_at) {
        this.form.end_at = null;
      }
    },
    uploadProfileImage(value) {
      this.form.image = value;
    },
    uploadWebImage(value) {
      this.form.image_web = value;
    },

    async onTypeChange(type) {
      this.form.redirect_type_obj = type;
      this.form.redirect_type = type.id;
      if (this.form.vendor_id) {
        if (this.form.redirect_type === AdRedirectTypes.product) {
          await this.$store.dispatch('admin/ads/getProducts', this.form.vendor_id);
        } else if (this.form.redirect_type === AdRedirectTypes.package) {
          await this.$store.dispatch('admin/ads/getPackages', this.form.vendor_id);
        }
      }
      this.form.redirect = null;
      this.form.redirect_obj = null;
    },
    async onVendorChange(vendor) {
      this.form.vendor = vendor;
      this.form.vendor_id = vendor.id;
      if (this.form.redirect_type === AdRedirectTypes.product) {
        await this.$store.dispatch('admin/ads/getProducts', this.form.vendor_id);
      } else if (this.form.redirect_type === AdRedirectTypes.package) {
        await this.$store.dispatch('admin/ads/getPackages', this.form.vendor_id);
      }
      this.form.redirect = null;
      this.form.redirect_obj = null;
    },

    onRedirectChange(redirect) {
      this.form.redirect_obj = redirect;
      this.form.redirect = redirect.id;

    },

    submitOrder() {

      if (this.modalData == null) {
        this.create(this.form).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, { root: true });
        });
      } else {
        this.update({
          ...this.form,
          id: this.modalData.id

        }).then(() => {
          this.$emit('onComplete', true);
          this.closeModal();
          this.$store.commit('loadingFinish', null, { root: true });
        });
      }
    },


    closeModal() {
      this.form = {
        name: '',
        image: '',
        image_web: '',
        redirect_type_obj: '',
        redirect_type: 0,
        vendor: '',
        vendor_id: '',
        redirect: '',
        start_at: '',
        end_at: ''
      };
      this.show = false;
    }
  }
};
</script>
